import { PureComponent } from 'react';
import { Link, Tooltip, Text, TextInput, NetworkIndicator, Button } from '@symbolic/rn-lib';
import { Image, View, TouchableOpacity } from 'react-native';
import { resourceActions } from '~/redux';
import { setActiveView } from '~/redux/active-view';
import { connect, setActiveOrg, updateOrg } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { api } from '@symbolic/lib';
import { getNameFor } from '~/helpers/product-order-helper';
import { accentColorForOrg } from '~/helpers/org-helper';
import { getLogoForOrg } from '~/helpers/get-logo-for-org';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';
import ProductOrderSharePopup from './popups/order-page-share-popup/order-page-share-popup';

import K from '~/k';
import UrlPattern from 'url-pattern';
import downArrowIcon from '~/assets/down-arrow-white.png';
import homeIcon from '~/assets/home-icon-white.png';
import backIcon from '~/assets/left-arrow-icon-white.png';
import _ from 'lodash';
import Cookies from 'js-cookie';

class HeaderContent extends PureComponent {
  state = {
    isPickingActiveOrg: false,
    pricingIsHiddenToInvitee: 0,
    shareOrderPopupIsVisible: false
  };

  constructor(props) {
    super(props);

    this.handleSearchInput = _.debounce(this.handleSearchInput.bind(this), 400);
  }

  async componentDidMount() {
    if (K.isWeb) this.setState({isHeadless: !!(await Cookies.get('isHeadless'))});
  }

  handleSearchInput({value: searchTerm}) {
    this.props.setActiveView({data: {searchTerm}});
  }

  handleDrawingSetMode = ({value}) => this.props.setActiveView({data: {drawingSetMode: value}});

  handleSelectOrg = ({orgId}) => {
    this.props.setActiveOrg({activeOrgId: orgId});

    this.setState({isPickingActiveOrg: false});

    // window.location.reload();
  };

  toggleShareOrderPopup = () => this.setState({shareOrderPopupIsVisible: !this.state.shareOrderPopupIsVisible});

  toggleGuestMode = async () => {
    var {activeOrg} = this.props.session;
    var isEmployee = activeOrg.role === 'member' || activeOrg.role === 'owner';

    if (isEmployee) {
      this.props.updateOrg({id: activeOrg.id, props: {role: 'guest', prevRole: activeOrg.role}, hitApi: false});
    }
    else if (!isEmployee) {
      this.props.updateOrg({id: activeOrg.id, props: {role: activeOrg.prevRole, prevRole: undefined}, hitApi: false});
    }
  };

  render() {
    var pathname = this.props.location.pathname;
    var {activeOrg} = this.props.session;
    var {id: orgId} = activeOrg;
    var {activeProductOrder, activeOrgId} = this.props;

    var isOrdersPage = _.endsWith(pathname, '/orders') || _.endsWith(pathname, '/orders/');
    var isOnDocumentsPage = _.includes(pathname, '/documents');
    var isAdminPage = pathname.includes('/admin');
    var isEmployee = _.includes(['owner', 'member'], activeOrg.role);

    var showSearch = isOrdersPage;

    var productOrderStatuses = _.get(activeOrg, 'appData.designEngine.productOrderStatuses');
    var isArchitectural = _.get(activeOrg, 'appData.designEngine.isArchitectural');

    if (activeProductOrder && productOrderStatuses) {
      var orderPages = [
        {title: 'EDIT', path: `/orders/${activeProductOrder.orgSpecificId}`},
      ];

      if (!isArchitectural) {
        orderPages.push({title: 'DOCUMENTS', path: `/orders/${activeProductOrder.orgSpecificId}/documents`});
      }

      if (!activeProductOrder.isStocked) {
        orderPages.push({title: 'DRAWINGS', path: `/orders/${activeProductOrder.orgSpecificId}/drawings`});
      }
    }

    var {isEmployee, isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    return (
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {activeProductOrder && isEmployee && (
            <Tooltip left text={isOnDocumentsPage ? getNameFor({orgId, textToTransform: ' Back to Order #'}) + _.replace(_.replace(pathname, '/documents', ''), '/orders/', '') : getNameFor({orgId, textToTransform: 'Back to Orders'})}>
              <Link to={isOnDocumentsPage ? _.replace(pathname, '/documents', '') : '/orders'} style={{width: K.button.width, alignItems: 'center'}}>
                <Image style={{...K.defaultIconSize}} source={backIcon} />
              </Link>
            </Tooltip>
          )}
          {((isEmployee || isGuest || isGuestMode) && activeOrg.role) && (
            <Tooltip left text='Home'>
              <Link to='/' style={{...K.button, alignItems: 'center'}}>
                <Image source={homeIcon} nativeID={'HeaderContentHomeButton'} style={{...K.defaultIconSize}}/>
              </Link>
            </Tooltip>
          )}
          {!isArchitectural && !isAdminPage && activeOrg.role === 'owner' && (
            <Link to='/admin' style={{marginLeft: K.spacing}}>
              <Text style={{color: '#fff'}}>ADMIN</Text>
            </Link>
          )}
          {!_.get(activeOrg, 'appData.designEngine.isEmployeeOnly') && (<>
            {(isEmployee || activeOrg.prevRole) && !isAdminPage && (
              <TouchableOpacity style={{marginLeft: K.spacing}} onPress={() => this.toggleGuestMode({activeOrg})}>
                {(isEmployee) && <Text style={{color: K.colors.base}}>GUEST MODE</Text>}
                {(!_.includes(['owner', 'member'], activeOrg.role)) && <Text style={{color: K.colors.base}}>EMPLOYEE MODE</Text>}
              </TouchableOpacity>
            )}
          </>)}
          {(activeProductOrder && (isEmployee || isGuest || isGuestMode) && activeOrg.role) && (
            <Button onPress={this.toggleShareOrderPopup} style={{width: 'auto', backgroundColor: 'none', marginLeft: K.spacing}}>
              <Text style={{color: '#fff'}}>SHARE</Text>
            </Button>
          )}
          {!!this.state.shareOrderPopupIsVisible && (
            <ProductOrderSharePopup onClose={this.toggleShareOrderPopup} {...{productOrder: activeProductOrder}} />
          )}
          {showSearch && (
            <TextInput
              style={{paddingHorizontal: K.spacing, backgroundColor: 'transparent', color: '#fff', ...(K.isWeb ? {width: 200, marginRight: K.margin} : {flex: 1})}}
              onInput={this.handleSearchInput}
              value={this.props.searchTerm}
              placeholder='SEARCH...'
              placeholderTextColor='rgba(255, 255, 255, 0.5)'
              returnKeyType={'done'}
              autoFocus={K.isWeb}
              onSubmitEditing={() => {
                this.props.setActiveView({data: {searchTerm: ''}});
              }}
            />
          )}
        </View>
        <View dataSet={{conditionalOpacityParent: 1}} style={{height: K.spacing * 2 + K.button.height - 20, zIndex: 10, position: 'absolute', width: 250, left: '50%', top: -K.spacing + 10, marginLeft: -125 + K.spacing * 2, flexDirection: 'row', alignItems: 'center'}}>
          <View style={{position: 'relative', height: '100%', width: '100%'}}>
            {((isEmployee || isGuest || isGuestMode) && activeOrg.role) ? (
              <Link to='/' style={{width: '100%', height: '100%'}}>
                {!this.state.isHeadless && getLogoForOrg({orgId: activeOrgId, colorVariant: 'white'}) && (
                  <Image source={getLogoForOrg({orgId: activeOrgId, colorVariant: 'white'})} resizeMode={'contain'} style={{width: '100%', height: '100%'}}/>
                )}
              </Link>
            ) : (
              <View style={{width: '100%', height: '100%'}}>
                {!this.state.isHeadless && getLogoForOrg({orgId: activeOrgId, colorVariant: 'white'}) && (
                  <Image source={getLogoForOrg({orgId: activeOrgId, colorVariant: 'white'})} resizeMode={'contain'} style={{width: '100%', height: '100%'}}/>
                )}
              </View>
            )}
          </View>
          {this.props.session.orgs.length > 1 && (isEmployee || isGuest || isGuestMode) && (<>
            <TouchableOpacity nativeID={'HeaderContentOrgDropdown'} dataSet={this.state.isHeadless ? {} : {conditionalOpacity: 1}} style={{position: 'absolute', right: -30, marginLeft: K.spacing, padding: K.spacing}} onPress={() => this.setState({isPickingActiveOrg: !this.state.isPickingActiveOrg})}>
              <Image source={downArrowIcon} style={{...K.defaultIconSize, transform: [{rotate: this.state.isPickingActiveOrg ? '180deg' : '0deg'}]}}/>
            </TouchableOpacity>
            {this.state.isPickingActiveOrg && (
              <View style={{position: 'absolute', top: '100%', paddingVertical: K.spacing, marginTop: 10, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: 250, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), borderRadius: K.borderRadius}}>
                {_.map(this.props.session.orgs, org => (
                  <TouchableOpacity
                    nativeID={`HeaderContentOrgDropdownOption-${org.id}`}
                    key={org.id}
                    style={{paddingHorizontal: K.spacing, paddingVertical: K.margin}}
                    onPress={() => this.handleSelectOrg({orgId: org.id})}
                  >
                    <Text style={{color: 'white', textAlign: 'center'}}>{org.title}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            )}
          </>)}
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {K.isWeb && !this.state.isHeadless && (
            <View style={{marginRight: K.spacing + K.margin}}>
              <NetworkIndicator />
            </View>
          )}
          {activeProductOrder && (
            <View style={{flexDirection: 'row', marginRight: K.margin * 4}}>
              {_.map(orderPages, (page, index) => {
                var isActiveOrderPageView = _.endsWith(pathname, page.path) || _.endsWith(pathname, `${page.path}/`);

                var isFirst = index === 0;
                var isLast = index === orderPages.length - 1;

                return (
                  <Link
                    key={`order-page-view-button-${index}`}
                    to={page.path}
                    style={{
                      height: 24,
                      paddingLeft: isFirst ? 12 : 8,
                      paddingRight: isLast ? 12 : 8,
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRightWidth: 0,
                      ...(isActiveOrderPageView && {backgroundColor: 'white'}),
                      ...(isFirst && {borderTopLeftRadius: K.borderRadius * 3, borderBottomLeftRadius: K.borderRadius * 3}),
                      ...(isLast && {borderTopRightRadius: K.borderRadius * 3, borderBottomRightRadius: K.borderRadius * 3, borderRightWidth: 1})
                    }}
                  >
                    <Text nativeID={`HeaderContentEditDrawingsInvoiceSwitcherOption-${page.title}`} style={{color: !isActiveOrderPageView && 'white', top: -1, ...K.fonts.label, opacity: 1}}>{page.title}</Text>
                  </Link>
                );
              })}
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var activeProductOrderIdPattern = new UrlPattern('/orders/:productOrderOrgSpecificId(/*)');

    if (activeProductOrderIdPattern.match(ownProps.location.pathname)) {
      var {productOrderOrgSpecificId} = activeProductOrderIdPattern.match(ownProps.location.pathname);
      var activeProductOrder = _.values(state.resources.productOrders.byFieldKeyIndex.orgSpecificId[productOrderOrgSpecificId])[0];
    }

    var activeOrgId = _.get(state, 'session.activeOrg.id');

    return {
      activeProductOrder,
      ..._.pick(state.activeView.data, ['searchTerm', 'hideInvoiceDiscount', 'invoiceMode']),
      activeOrgId,
    };
  },
  mapDispatch: {
    setActiveView, setActiveOrg, updateOrg,
    ..._.pick(resourceActions.productOrders, ['trackProductOrders'])
  }

})(HeaderContent));
