export default function getScrews({group, path, circle, screwTypeOptionId, rectWidth, rectHeight, productIsLamp = false, customInteraxis = 0}) {
  var axis = rectWidth >= rectHeight ? 'X' : 'Y';
  if (productIsLamp && !(rectWidth === 82 && rectHeight === 82)) axis = 'Y';

  var screwSpacing;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 82) screwSpacing = 60;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 91) screwSpacing = 66;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 117) screwSpacing = 83;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 133) screwSpacing = 107;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 144) screwSpacing = 117;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 175) screwSpacing = 150;
  if ((rectWidth >= rectHeight ? rectWidth : rectHeight) === 207) screwSpacing = 170;
  // if (rectWidth === 117 && rectHeight === 117) screwSpacing = 47.7;

  if (customInteraxis !== 0) screwSpacing = customInteraxis;

  var screwBasedOnType = {5: 'torxScrew', 6: 'slotHeadScrew'};
  var screwRadius = 3.3;

  var screwPositions;

  if (rectWidth === 117 && rectHeight === 117) {
    screwPositions = {
      a: {
        x: (rectWidth / 2) - (23) - screwRadius,
        y: (rectHeight / 2) - (41.5) - screwRadius
      },
      b: {
        x: (rectWidth / 2) - (23) - screwRadius,
        y: (rectHeight / 2) + (41.5) - screwRadius
      },
      c: {
        x: (rectWidth / 2) + (23) - screwRadius,
        y: (rectHeight / 2) - (41.5) - screwRadius
      },
      d: {
        x: (rectWidth / 2) + (23) - screwRadius,
        y: (rectHeight / 2) + (41.5) - screwRadius
      }
    };
  }
  else {
    screwPositions = {
      a: {
        x: (rectWidth / 2) - (axis === 'X' ? (screwSpacing / 2) : 0) - screwRadius,
        y: (rectHeight / 2) - (axis === 'Y' ? (screwSpacing / 2) : 0) - screwRadius
      },
      b: {
        x: (rectWidth / 2) + (axis === 'X' ? (screwSpacing / 2) : 0) - screwRadius,
        y: (rectHeight / 2) + (axis === 'Y' ? (screwSpacing / 2) : 0) - screwRadius
      }
    };
  }

  function calcStarPoints(centerX, centerY, innerCirclePoints, innerRadius, outerRadius) {
    var angle = (Math.PI / innerCirclePoints);
    var angleOffsetToCenterStar = 0;

    var totalPoints = innerCirclePoints * 2; // 10 in a 5-points star

    var points = [];
    for (let i = 0; i < totalPoints; i++) {
      var isEvenIndex = i % 2 == 0;
      var r = isEvenIndex ? outerRadius : innerRadius;
      var currX = centerX + Math.cos(i * angle + angleOffsetToCenterStar ) * r;
      var currY = centerY + Math.sin(i * angle + angleOffsetToCenterStar) * r;

      if (i === 0) {
        points.push({command: 'M', x: currX, y: currY});
      }
      else {
        points.push({command: 'L', x: currX, y: currY});
      }
    }

    var firstPoint = _.first(points);
    points.push({command: 'L', x: firstPoint.x, y: firstPoint.y});

    return points;
  }

  function calculateLineXYOnCircle({cx, cy, angle, radius}) {
    angle = angle * (Math.PI / 180);
    return {
      x: cx + (radius * Math.cos(angle)),
      y: cy + (radius * Math.sin(angle))
    };
  }

  var screws = {
    torxScrew: ({x, y}) => group({width: screwRadius * 2, height: screwRadius * 2, x, y}, ([
      circle({cx: screwRadius, cy: screwRadius, r: screwRadius, stroke: 'black', strokeWidth: 1, fill: 'transparent'}),
      path({d: calcStarPoints(screwRadius, screwRadius, 6, 1, 1.7), strokeWidth: 1, stroke: 'black', fill: 'transparent'})
    ])),

    slotHeadScrew: ({x, y}) => {
      var firstPoint = calculateLineXYOnCircle({cx: screwRadius, cy: screwRadius, angle: 278, radius: screwRadius});
      var secondPoint = calculateLineXYOnCircle({cx: screwRadius, cy: screwRadius, angle: 82, radius: screwRadius});
      var thirdPoint = calculateLineXYOnCircle({cx: screwRadius, cy: screwRadius, angle: 264, radius: screwRadius});
      var fourthPoint = calculateLineXYOnCircle({cx: screwRadius, cy: screwRadius, angle: 96, radius: screwRadius});

      return group({width: 8, height: 8, x, y}, ([
        circle({cx: screwRadius, cy: screwRadius, r: screwRadius, stroke: 'black', strokeWidth: 1, fill: 'transparent'}),
        path({d: [
          {command: 'M', x: firstPoint.x, y: firstPoint.y},
          {command: 'L', x: secondPoint.x, y: secondPoint.y},
        ], strokeWidth: 1, stroke: 'black', fill: 'transparent'}),
        path({d: [
          {command: 'M', x: thirdPoint.x, y: thirdPoint.y},
          {command: 'L', x: fourthPoint.x, y: fourthPoint.y},
        ], strokeWidth: 1, stroke: 'black', fill: 'transparent'})
      ]));
    }
  };

  var returnObject;

  if (rectWidth === 117 && rectHeight === 117) {
    returnObject = screwTypeOptionId === 103 || screwTypeOptionId === 0
      ? []
      : group({x: 0, y: 0}, [
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.a),
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.b),
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.c),
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.d)
      ]);
  }
  else {
    returnObject = screwTypeOptionId === 103 || screwTypeOptionId === 0
      ? []
      : group({x: 0, y: 0}, [
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.a),
        screws[screwBasedOnType[screwTypeOptionId]](screwPositions.b),
      ]);
  }

  return returnObject;
}