import { products } from '@symbolic/lib';

var {getKeypadData, getMechanismLayoutData} = products.meljac;

export default function getIsBacklitEngravingCompatible({activeProductInstanceWithData}) {
  var isBacklitEngravingCompatible = true; //TODO should this default to false?

  if (activeProductInstanceWithData.product.productCategoryId === 1) { //HINT type keypad
    var {plateSizePropertyId, mechanismLayoutPropertyId, backBoxId} = getKeypadData({productId: activeProductInstanceWithData.productId, productInstanceWithData: activeProductInstanceWithData});

    var selectedPlateSize = _.get(activeProductInstanceWithData, `propertiesDataById.${plateSizePropertyId}.selectedProductOption`);

    var plateHeight = _.get(selectedPlateSize, 'data.height');
    var plateWidth = _.get(selectedPlateSize, 'data.width');

    var {totalRows, totalColumns} = getMechanismLayoutData({mechanismLayoutPropertyId, productInstanceWithData: activeProductInstanceWithData});

    var exceptionConditions = [
      (plateWidth === 82 && plateHeight === 144 && totalColumns === 2 && totalRows === 4 && backBoxId === 1424), //HINT 82x144mm keypad, with 2x4 button layout, and B00815 backbox
      (plateWidth === 82 && plateHeight === 82 && totalColumns <= 2), //HINT 82x82mm keypad, with 2 or less columns
      ((
        (plateWidth === 117 && plateHeight === 82) ||
        (plateWidth === 144 && plateHeight === 82) ||
        (plateWidth === 207 && plateHeight === 82)
      ) && totalRows === 1) //HINT certain plate sizes with a single row of mechanisms
    ];

    if (!_.some(exceptionConditions)) { //HINT if does not meet one of the exception conditions
      if (
        (plateHeight === 82 && totalRows > 1) ||
        (plateHeight === 117 && totalRows > 2) ||
        (plateHeight === 144 && totalRows > 3) ||
        (plateHeight === 207 && totalRows > 5) ||
        (plateWidth === 82 && totalColumns > 2) ||
        (plateWidth === 117 && totalColumns > 2) ||
        (plateWidth === 144 && totalColumns > 3) ||
        (plateWidth === 207 && totalColumns > 4)
      ) {
        isBacklitEngravingCompatible = false;
      }
    }
  }

  return isBacklitEngravingCompatible;
}