import { PureComponent } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text, Button, ScrollView, Label, CheckboxInput, LabelledView, TextInput, Link } from '@symbolic/rn-lib';
import { formatPrice } from '~/helpers/price-helper';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getNameFor } from '~/helpers/product-order-helper';

import K from '~/k';
import OrderCostsTable from '~/components/order-costs-table';
import _ from 'lodash';
import { api } from '@symbolic/lib';

import StripeCheckoutForm from '~/components/stripe-checkout-form';

class AccordionSection extends PureComponent {
  render() {
    var {title, children, onChange, id, styles = {outerView: {}, titleView: {}, radioButton: {}, title: {}}, disabled} = this.props;

    return (
      <View style={styles.outerView}>
        <View style={{flexDirection: 'row', alignItems: 'center', ...styles.titleView}}>
          <TouchableOpacity
            disabled={disabled}
            style={{width: 16, height: 16, borderRadius: 8, opacity: disabled ? 0.5 : 1, backgroundColor: 'transparent', borderWidth: 1, ...styles.radioButton}}
            onPress={() => onChange({value: id})}
          />
          <Text style={{fontWeight: '400', textTransform: 'uppercase', marginLeft: 20, opacity: disabled ? 0.5 : 1, ...styles.title}}>{title}</Text>
        </View>
        {children}
      </View>
    );
  }
}

class PaymentForm extends PureComponent {
  handlePaymentMethodChange = ({value: activePaymentSectionId}) => this.props.onPaymentMethodChange(activePaymentSectionId);

  handlePayInFullChange = ({value}) => this.props.onPayInFullChange({value});

  renderAccordionSectionFor({paymentMethod, awaitingPaymentMethod}) {
    var {key, title} = paymentMethod;
    var {productOrder, activePaymentMethod, paymentAmount, shouldApplyUpcharge, upchargeAmount, onConfirmPayment, handleStripePaymentMethodChange, isCardPayment, stripeActivePaymentMethod, session, isEmployee, payInFull, shippedWithoutFinalPayment, activeProductOrderStatus} = this.props;

    var disableAccordionSection = (awaitingPaymentMethod && awaitingPaymentMethod !== paymentMethod.key) || paymentMethod.disabled === 1;

    var accordionSectionStyles = {outerView: {marginVertical: K.spacing * 2}, radioButton: {backgroundColor: activePaymentMethod === key ? 'black' : 'transparent'}};
    var addressTextStyle = {borderLeftWidth: 1, borderColor: K.colors.doubleGray, color: K.colors.overlay, paddingLeft: 10, paddingVertical: 5};
    var submitButtonStyle = {borderRadius: 0, marginTop: K.spacing * 4, paddingHorizontal: K.spacing * 1.5};

    if (paymentMethod.isPreferred) title += ' (Preferred)';

    var formattedPaymentAmount = formatPrice(paymentAmount);

    var awaitingPaymentMessage = () => {
      return (
        <View style={{marginTop: K.spacing * 4}}>
          <Text>THANK YOU FOR CONFIRMING PAYMENT</Text>
          <Text style={{marginTop: K.spacing}}>WE WILL UPDATE THE STATUS OF YOUR ORDER WHEN PAYMENT IS RECEIVED</Text>
        </View>
      );
    };

    if (key === 'stripe') {
      var {stripePaymentMethodOrder} = paymentMethod.props;
    }
    else if (key === 'wire') {
      var {companyName, bank, streetAddress, city, state, zip, routingNumber, accountNumber} = paymentMethod.props;
    }
    else if (key === 'checkByMail') {
      var {companyName, streetAddress, city, state, zip} = paymentMethod.props;
    }

    return (
      <AccordionSection
        key={key}
        disabled={disableAccordionSection}
        id={key}
        title={title}
        onChange={this.handlePaymentMethodChange}
        styles={accordionSectionStyles}
      >
        {activePaymentMethod === key && (
          <View style={{marginTop: K.spacing * 2, marginLeft: K.spacing * 3}}>
            {key === 'stripe' && (
              <View>
                {productOrder.isProcessingPayment === 1 ? (
                  <View>
                    <Text style={{marginBottom: K.spacing * 2}}>Payment processing...</Text>
                    <Text style={{marginBottom: K.margin}}>You can safely close this window or navigate to another order.</Text>
                    <Text>You will receive a notifcation when payment processing is complete.</Text>
                  </View>
                ) : (
                  <View>
                    <View style={{flex: 1}}>
                      <StripeCheckoutForm
                        productOrderId={productOrder.id}
                        paymentAmount={shouldApplyUpcharge ? (paymentAmount + upchargeAmount) : paymentAmount}
                        formattedPaymentAmount={shouldApplyUpcharge ? formatPrice(paymentAmount + upchargeAmount) : formattedPaymentAmount}
                        paymentAmountBeforeUpcharge={paymentAmount}
                        {...{upchargeAmount, stripePaymentMethodOrder, handleStripePaymentMethodChange, isCardPayment, activePaymentMethod: stripeActivePaymentMethod, activeOrgId: session.activeOrg.id, isEmployee, payInFull, shippedWithoutFinalPayment, productOrder, activeProductOrderStatus}}
                      />
                    </View>
                  </View>
                )}
              </View>
            )}
            {key === 'wire' && (
              <View>
                <Text>{`PLEASE INCLUDE ORDER #${productOrder.orgSpecificId} IN THE MEMO`}</Text>
                <View style={{flexDirection: 'row', marginTop: K.spacing * 2}}>
                  <View style={{width: '30%', paddingTop: 5}}>
                    <Text>WIRES SHOULD BE SENT TO: </Text>
                  </View>
                  <View>
                    <Text style={{...addressTextStyle, marginBottom: 5}}>{companyName}</Text>
                    <Text style={addressTextStyle}>{bank}</Text>
                    <Text style={addressTextStyle}>{streetAddress}</Text>
                    <Text style={addressTextStyle}>{city}, {state} {zip}</Text>
                  </View>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: K.spacing * 1.5}}>
                  <View style={{width: '30%'}}>
                    <Text>ROUTING/ABA #</Text>
                  </View>
                  <View>
                    <Text style={addressTextStyle}>{routingNumber}</Text>
                  </View>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: K.spacing * 1.5}}>
                  <View style={{width: '30%'}}>
                    <Text>ACCOUNT #</Text>
                  </View>
                  <View>
                    <Text style={addressTextStyle}>{accountNumber}</Text>
                  </View>
                </View>
                <View style={{alignSelf: 'flex-start'}}>
                  {awaitingPaymentMethod === paymentMethod.key ? awaitingPaymentMessage() : (
                    <Button
                      label={`I'VE INITIATED A WIRE FOR $${formatPrice(paymentAmount)}`}
                      disabled={productOrder.isProcessingPayment} mode={'dark'}
                      style={submitButtonStyle}
                      onPress={() => onConfirmPayment({
                        paymentData: {
                          amount: paymentAmount
                        }
                      })}
                    />
                  )}
                </View>
              </View>
            )}
            {key === 'checkByMail' && (
              <View>
                <Text>{`PLEASE INCLUDE ORDER #${productOrder.orgSpecificId} IN THE MEMO`}</Text>
                <View style={{flexDirection: 'row', marginTop: K.spacing * 2}}>
                  <View style={{width: '30%', paddingTop: 5}}>
                    <Text>CHECK BY MAIL SHOULD BE SENT TO: </Text>
                  </View>
                  <View>
                    <Text style={addressTextStyle}>{companyName}</Text>
                    <Text style={addressTextStyle}>{streetAddress}</Text>
                    <Text style={addressTextStyle}>{city}, {state} {zip}</Text>
                  </View>
                </View>
                <View style={{alignSelf: 'flex-start'}}>
                  {awaitingPaymentMethod === paymentMethod.key ? awaitingPaymentMessage() : (
                    <Button
                      label={`I'VE SENT A CHECK FOR $${formattedPaymentAmount}`}
                      disabled={productOrder.isProcessingPayment} mode={'dark'}
                      style={submitButtonStyle}
                      onPress={() => onConfirmPayment({
                        paymentData: {
                          amount: paymentAmount
                        }
                      })}
                    />
                  )}
                </View>
              </View>
            )}
          </View>
        )}
      </AccordionSection>
    );
  }

  componentDidMount() {
    if (this.props.productOrder.isProcessingPayment === 1) {
      this.pollProductOrderIsProcessingInterval = setInterval(this.pollProductOrderIsProcessingPayment, 2000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollProductOrderIsProcessingInterval);
  }

  pollProductOrderIsProcessingPayment = async () => {
    var productOrder = await api.get('productOrder', {where: {id: this.props.productOrder.id}});

    if (this.props.productOrder.isProcessingPayment !== productOrder.isProcessingPayment) {
      this.props.updateProductOrder({id: this.props.productOrder.id, props: {...productOrder}, hitApi: false});

      clearInterval(this.pollProductOrderIsProcessingInterval);
    }
  };

  handleClientEmailsInputChange = ({value}) => this.props.updateProductOrder({id: this.props.productOrder.id, props: {clientEmails: value}});

  render() {
    var {session, style, paymentAmount, orderCosts, productOrder, productInstances, paymentMethods, lastCompletedProductOrderStatus, shouldApplyUpcharge, upchargePercentage, upchargeAmount, isGuestMode, isEmployee, payInFull, shippedWithoutFinalPayment} = this.props;

    var isFinalPayment = orderCosts.depositAmount > 0 && productOrder.amountPaidInCents === orderCosts.depositAmount || shippedWithoutFinalPayment;

    var payInFullIsVisible = orderCosts.depositAmount > 0 && paymentAmount > 0 && !isFinalPayment;

    var dataForlastCompletedProductOrderStatus = _.get(productOrder, `statusData.${lastCompletedProductOrderStatus.key}`);
    var awaitingPaymentMethod = _.get(dataForlastCompletedProductOrderStatus, 'awaitingPaymentMethod');

    if (awaitingPaymentMethod) this.handlePaymentMethodChange({value: awaitingPaymentMethod});

    var sortedPaymentMethods = _.filter(_.sortBy(paymentMethods, 'rank'), (paymentMethod) => {
      var isHidden = paymentMethod.isHidden === 1;
      var isEnabledForUser = paymentMethod.isEmployeeOnly === 1 ? (!isGuestMode && isEmployee) : true;

      return !isHidden && isEnabledForUser;
    });

    var manualPaymentMessage = _.get(session, 'activeOrg.appData.designEngine.manualPaymentMessage', 'We will contact you to arrange payment.');

    var deliveryCountry = _.get(productOrder, 'deliveryAddress.country', 'US');

    var isDeliveryAddressIncomplete = !_.every(_.map(['city', 'state', 'streetAddress', 'zip'], (field) => !!productOrder.deliveryAddress[field]));

    if (isDeliveryAddressIncomplete || (!_.get(productOrder, 'clientEmails') && session.activeOrg.id === 850)) {
      return (
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{backgroundColor: K.colors.gray, padding: K.spacing * 2}}>
            {isDeliveryAddressIncomplete && (
              <Text style={{marginBottom: K.spacing}}>A complete delivery address is required to proceed.</Text>
            )}
            {!_.get(productOrder, 'clientEmails') && session.activeOrg.id === 850 && (
              <Text style={{marginBottom: K.spacing}}>Contact email is required to proceed.</Text>
            )}
            <Text
              dataSet={{fadeOnHover: 1}}
              style={{textAlign: 'center', marginTop: K.spacing, opacity: 0.5}}
              onPress={this.props.showOrderSettingsPopup}
            >
              {getNameFor({productOrder, orgId: session.activeOrg.id, textToTransform: 'Edit Order Details'})}
            </Text>
          </View>
        </View>
      );
    }
    else {
      return (
        <ScrollView style={{width: 750, backgroundColor: 'white', paddingHorizontal: K.spacing * 3, paddingVertical: K.spacing * 2, margin: 'auto', ...K.shadow, ...style}}>
          <View style={{marginBottom: K.spacing * 2}}>
            <Text>
              {sortedPaymentMethods.length < 1 ? 'Your order is ready for payment. Once payment is confirmed, your order will be moved to the next step.' : 'Your order is ready for payment. Please select a payment method and fill out the form below to move to the next step.'}
            </Text>
          </View>
          <View style={{marginBottom: K.spacing}}>
            <Label style={{marginBottom: K.margin, fontWeight: 'bold'}}>Delivery Address</Label>
            <Text style={{...K.fonts.su, color: K.colors.overlay}}>{_.get(productOrder.deliveryAddress, 'streetAddress')}</Text>
            <Text style={{...K.fonts.su, color: K.colors.overlay}}>{_.get(productOrder.deliveryAddress, 'streetAddress2')}</Text>
            <Text style={{...K.fonts.su, color: K.colors.overlay}}>{_.get(productOrder.deliveryAddress, 'city')}, {_.get(productOrder.deliveryAddress, 'state')} {_.get(productOrder.deliveryAddress, 'zip')}</Text>
            <Text style={{...K.fonts.su, color: K.colors.overlay}}>{!_.isEmpty(deliveryCountry) ? deliveryCountry : 'United States'}</Text>
          </View>
          {payInFullIsVisible && (
            <View style={{marginBottom: K.spacing * 2, marginLeft: -K.spacing}}>
              <CheckboxInput
                label={'Pay deposit amount only'}
                value={payInFull === true ? 0 : 1}
                onChange={({value}) => this.handlePayInFullChange({value: value === 0 ? true : false})}
                style={{marginTop: K.spacing, width: '40%'}}
              />
              <CheckboxInput
                label={'Pay in full'}
                value={payInFull === true ? 1 : 0}
                onChange={({value}) => this.handlePayInFullChange({value: value === 1 ? true : false})}
                style={{marginTop: K.spacing, width: '40%'}}
              />
              {!payInFull && (
                <Text style={{opacity: 0.5, fontSize: K.calc(14), marginTop: K.spacing, marginLeft: K.spacing}}>
                  Final payment will be due once order is complete and ready to ship
                </Text>
              )}
              {(shouldApplyUpcharge && upchargeAmount) && (
                <Text style={{opacity: 0.5, fontSize: K.calc(14), marginTop: K.spacing, marginLeft: K.spacing}}>
                  {`Card payments over $3,000 include a ${parseFloat(upchargePercentage / 10, 3)}% processing fee`}
                </Text>
              )}
            </View>
          )}
          <View style={{marginBottom: K.spacing * 2}}>
            <Label style={{marginBottom: K.margin, fontWeight: 'bold'}}>Cost Summary</Label>
            <OrderCostsTable
              styles={{
                outerView: {width: 275},
                title: {...K.fonts.su, color: K.colors.overlay},
                value: {...K.fonts.su, color: K.colors.overlay}
              }}
              {...{orderCosts, productOrder, productInstances, paymentAmount, payInFull, shouldApplyUpcharge, upchargePercentage, upchargeAmount}}
            />
          </View>
          <View style={{marginBottom: K.spacing * 2}}>
            <Text style={{...K.fonts.pageHeader, marginBottom: K.spacing}}>{'AMOUNT DUE'}</Text>
            {(shouldApplyUpcharge && upchargeAmount) ? (
              <Text style={{...K.fonts.pageHeader}}>${formatPrice(paymentAmount + upchargeAmount)}</Text>
            ) : (
              <Text style={{...K.fonts.pageHeader}}>${formatPrice(paymentAmount)}</Text>
            )}
          </View>
          {sortedPaymentMethods.length < 1 ? (
            <View>
              <Text>{manualPaymentMessage}</Text>
            </View>
          ) : (
            <View>
              {paymentAmount > 0 && (
                <>
                  {_.map(sortedPaymentMethods, (paymentMethod) => this.renderAccordionSectionFor({paymentMethod, shouldApplyUpcharge, upchargeAmount, awaitingPaymentMethod}))}
                </>
              )}
            </View>
          )}
        </ScrollView>
      );
    }
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder'])
  }
})(PaymentForm);
